@import url('https://fonts.googleapis.com/css2?family=Oswald&family=PT+Serif:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@media print {
    body {
        margin: 0 !important;
    }
}

.main-container {
    font-family: 'Lato', serif;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.ck-content {
    font-family: 'Lato', serif;
    line-height: 1.6;
    word-break: break-word;
}

.editor-container_classic-editor .editor-container__editor {
    width: 100%;
}

.ck-content h3.category {
    color: #555;
    font-family: 'Oswald', serif;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 10px;
    margin: 0;
    padding: 0;
}

.ck-content h2.document-title {
    border: 0;
    font-family: 'Oswald', serif;
    font-size: 50px;
    font-weight: bold;
    margin: 0;
    padding: 0;
}

.ck-content h3.document-subtitle {
    color: #555;
    font-family: 'Oswald', serif;
    font-size: 20px;
    font-weight: bold;
    margin: 0 0 1em;
    padding: 0;
}

.ck-content p.info-box {
    background: linear-gradient(
            135deg,
            var(--background-color) 0%,
            var(--background-color) var(--background-size),
            transparent var(--background-size)
    ),
    linear-gradient(
            135deg,
            transparent calc(100% - var(--background-size)),
            var(--background-color) calc(100% - var(--background-size)),
            var(--background-color)
    );
    border: 1px solid var(--background-color);
    border-radius: 10px;
    box-shadow: 5px 5px 0 #ffe6ef;
    --background-color: #e91e63;
    margin: 1.5em 2em;
    padding: 1.2em 2em;
    --background-size: 30px;
}

.ck-content blockquote.side-quote {
    border: 0;
    float: right;
    font-family: 'Oswald', serif;
    font-style: normal;
    margin-left: 1em;
    overflow: visible;
    position: relative;
    width: 35%;
    z-index: 1;
}

.ck-content blockquote.side-quote::before {
    color: #e7e7e7;
    content: '“';
    display: block;
    font-size: 200px;
    left: -10px;
    line-height: 1;
    position: absolute;
    top: -37px;
    z-index: -1;
}

.ck-content blockquote.side-quote p {
    font-size: 2em;
    line-height: 1;
}

.ck-content blockquote.side-quote p:last-child:not(:first-child) {
    color: #555;
    font-size: 1.3em;
    text-align: right;
}

.ck-content span.marker {
    background: yellow;
}

.ck-content span.spoiler {
    background: #000;
    color: #000;
}

.ck-content span.spoiler:hover {
    background: #000;
    color: #fff;
}

.ck-content pre.fancy-code {
    border: 0;
    border-radius: 10px;
    margin-left: 2em;
    margin-right: 2em;
}

.ck-content pre.fancy-code::before {
    background: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1NCAxMyI+CiAgPGNpcmNsZSBjeD0iNi41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiNGMzZCNUMiLz4KICA8Y2lyY2xlIGN4PSIyNi41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiNGOUJFNEQiLz4KICA8Y2lyY2xlIGN4PSI0Ny41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiM1NkM0NTMiLz4KPC9zdmc+Cg==) no-repeat;
    content: '';
    display: block;
    height: 13px;
    margin-bottom: 8px;
}

.ck-content pre.fancy-code-dark {
    background: #272822;
    box-shadow: 5px 5px 0 #0000001f;
    color: #fff;
}

.ck-content pre.fancy-code-bright {
    background: #dddfe0;
    box-shadow: 5px 5px 0 #b3b3b3;
    color: #000;
}

.ck-editor__editable_inline {
    max-height: 400px; /* Hauteur maximale en pixels */
    overflow-y: auto; /* Ajoute un ascenseur si le contenu dépasse la hauteur */
}